window.onload = function () {
    document.getElementById('loader').remove();
};

$(document).ready(function () {
    // Scroll Effect
    $(window).scroll(function () {
        if ($(window).scrollTop() > 200) {
            $('.btn-top').addClass(
                'd-block animate__bounceInUp animate__animated'
            );
            $('.btn-top').removeClass('d-none');
            $('.navbar').addClass(
                ' animate__animated animate__slideInDown fixed-top'
            );
        } else {
            $('.btn-top').removeClass(
                'd-block animate__bounceInUp animate__animated'
            );
            $('.btn-top').addClass('d-none');
            $('.navbar').removeClass(' animate__slideInDown fixed-top');
        }
    });
    $('.btn-top a').click(function (e) {
        e.preventDefault();
        $('html, body').animate(
            {
                scrollTop: 0,
            },
            300
        );
    });
});
